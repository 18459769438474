import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { ImageBackground } from "components/background/background";
import NavBar from "components/navbar/NavBar";
import Footer from "layouts/home/components/Footer";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const BlogLayout = ({ children }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;
  return (
    <ImageBackground>
      <style jsx>
        {`
          figure {
            text-align: center;
            margin: 2rem 0;
          }
        `}
      </style>
      <NavBar
        links={
          <>
            {/* <Link to="/products">Products</Link> */}
            <Link to={"/"}>Home</Link>
            <a href={"/#products"}>Features</a>
            <Link to={"/blog"}>Blogs</Link>
          </>
        }
      />
      <Box
        sx={{
          borderRadius: "20px",
          backgroundColor: white.main,
          padding: "2rem 4rem",
          width: "79%",
          margin: "auto",
          maxWidth: "990px",
          marginY: "2rem",
          boxShadow: "0 4px 14px 0 #6A6BFF99",
          "@media (max-width: 700px)": {
            width: "89%",
            padding: "2rem",
          },
          "@media (max-width: 500px)": {
            width: "89%",
            padding: "1rem",
          },
        }}
      >
        {children}
      </Box>
      <Footer />
    </ImageBackground>
  );
};

export default BlogLayout;
