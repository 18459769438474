import { Box } from "@mui/material";
import image1 from "assets/images/hero/iconsset.png";
import image2 from "assets/images/hero/central-image.png";
import image3 from "assets/images/hero/result.png";
import processflow from "assets/images/hero/processflow.png";
import process from "assets/images/hero/process.svg";
import arrow1 from "assets/images/hero/arrow1.svg";
import arrow2 from "assets/images/hero/arrow2.svg";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import { sparkleSvg } from "components/svgIcons/svgIcons";
import { useTheme } from "@emotion/react";

const ImageSection = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const images = [
    {
      src: image1,
      style: {
        zIndex: "1",
        height: "390px",
        left: "10px",
        "@media (max-width: 1200px)": {
          height: "calc(390px * .7)",
          left: "calc(10px * .7)",
        },
        "@media (max-width: 900px)": {
          height: "calc(390px * .55)",
          left: "calc(10px * .55)",
        },
        "@media (max-width: 700px)": {
          height: "calc(390px * .4)",
          left: "calc(10px * .4)",
        },
        "@media (max-width: 500px)": {
          height: "calc(390px * .25)",
          left: "calc(10px * .25)",
        }
      },
    },
    {
      src: arrow1,
      style: {
        zIndex: "0",
        height: "240px",
        left: "-30px",
        "@media (max-width: 1200px)": {
          height: "calc(240px * .7)",
          left: "calc(-30px * .7)",
        },
        "@media (max-width: 900px)": {
          height: "calc(240px * .55)",
          left: "calc(-30px * .55)",
        },
        "@media (max-width: 700px)": {
          height: "calc(240px * .4)",
          left: "calc(-30px * .4)",
        },
        "@media (max-width: 500px)": {
          height: "calc(240px * .25)",
          left: "calc(-30px * .25)",
        }
      },
    },
    {
      src: image2,
      style: {
        zIndex: "0",
        height: "300px",
        top: "20px",
        left: "-40px",
        "@media (max-width: 1200px)": {
          height: "calc(300px * .7)",
          top: "calc(20px * .7)",
          left: "calc(-40px * .7)",

        },
        "@media (max-width: 900px)": {
          height: "calc(300px * .55)",
          top: "calc(20px * .55)",
          left: "calc(-40px * .55)",
        },
        "@media (max-width: 700px)": {
          height: "calc(300px * .4)",
          top: "calc(20px * .4)",
          left: "calc(-40px * .4)",
        },
        "@media (max-width: 500px)": {
          height: "calc(300px * .25)",
          top: "calc(20px * .25)",
          left: "calc(-40px * .25)",
        }
      },
    },
    {
      src: arrow2,

      style: {
        zIndex: "1",
        height: "250px",
        left: "-40px",
        "@media (max-width: 1200px)": {
          height: "calc(250px * .7)",
          left: "calc(-40px * .7)",
        },
        "@media (max-width: 900px)": {
          height: "calc(250px * .55)",
          left: "calc(-40px * .55)",
        },
        "@media (max-width: 700px)": {
          height: "calc(250px * .4)",
          left: "calc(-40px * .4)",
        },
        "@media (max-width: 500px)": {
          height: "calc(250px * .25)",
          left: "calc(-40px * .25)",
        }
      },
    },
    {
      src: image3,
      style: {
        zIndex: "0",
        height: "310px",
        left: "-45px",
        "@media (max-width: 1200px)": {
          height: "calc(310px * .7)",
          left: "calc(-45px * .7)",
        },
        "@media (max-width: 900px)": {
          height: "calc(310px * .55)",
          left: "calc(-45px * .55)",
        },
        "@media (max-width: 700px)": {
          height: "calc(310px * .4)",
          left: "calc(-45px * .4)",
        },
        "@media (max-width: 500px)": {
          height: "calc(310px * .25)",
          left: "calc(-45px * .25)",
        }
      },
    },
  ];
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          padding: "2rem",
          marginTop:"2rem",
          // move up on mobile
          "@media (max-width: 700px)": {
            marginTop: "-2rem",
            marginBottom: "-2rem",
          },
          marginBottom:"2rem",
          fontSize: "2.5rem",
          position: "relative",
          letterSpacing: "-1px",
          fontWeight: "500",
        }}
        className={"quicksand heading"}
      >
        What we do
        <span
          style={{
            fontSize: "2rem",
            position: "relative",
            top: "1rem",
            marginleft: "10px",
          }}
        >
          {sparkleSvg(primary.main)}
        </span>
      </Box>
      <Box
        sx={{
          marginX: "1rem",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          paddingLeft: "2rem",
          // No padding on mobile
          "@media (max-width: 600px)": {
            paddingLeft: "0",
            marginRight: "0",
          },
          paddingY: "2rem",
        }}
      >
        {images.map((image, index) => (
          <ScrollAnimation
            animateIn="fadeIn"
            delay={50 * index}
            style={{
              position: "relative",
              zIndex: image.style.zIndex,
            }}
          >
            <Box
              component={"img"}
              key={index}
              src={image.src}
              sx={{
                position: "relative",
                ...image.style,
              }}
            />
          </ScrollAnimation>
        ))}
{/* 
        <ScrollAnimation
          animateIn="fadeIn"
          delay={300}
          style={{
            position: "relative",
            zIndex: "0",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box
            component={"img"}
            position={"relative"}
            src={processflow}
            sx={{
              top: "20px",
              "@media (max-width: 3000px)": {
                height: "calc(600px)",
              },
              "@media (max-width: 1100px)": {
                height: "calc(600px * .7)",
              },
              "@media (max-width: 900px)": {
                height: "calc(600px * .5)",
              },
              "@media (max-width: 600px)": {
                height: "calc(600px * .4)",
              },
            }}
          />
        </ScrollAnimation> */}
      </Box>
    </>
  );
};

export default ImageSection;
