import { Box } from "@mui/material";
import HeroCard from "./HeroCard";
import { useTheme } from "@emotion/react";
import { sparkleSvg } from "components/svgIcons/svgIcons";
import cardBg from "assets/images/backgrounds/card-bg.png";
import heroCard1 from "assets/images/hero/hero-card1.png";
import heroCard2 from "assets/images/hero/hero-card2.png";
import heroCard3 from "assets/images/hero/hero-card3.png";

const WhyNeatProcess = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          padding: "5rem",
          paddingTop: "2rem",
          fontSize: "2.5rem",
          position: "relative",
          letterSpacing: "-1px",
          fontWeight: "500",
        }}
        className={"quicksand heading"}
      >
        Why NeatProcess?
        <span
          style={{
            fontSize: "2rem",
            position: "relative",
            top: "1rem",
            marginleft: "10px",
          }}
        >
          {sparkleSvg(primary.main)}
        </span>
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: "1.5rem",
          justifyContent: "center",
          alignItems: "center",
          height: "396px",
          width: "90%",
          maxWidth: "1120px",
          margin: "auto",
          gridTemplateColumns: `repeat(3, 1fr)`,
          "@media (max-width: 900px)": {
            height: "300px",
          },
          "@media (max-width: 700px)": {
            gridTemplateColumns: `repeat(1, 1fr)`,
            height: "auto",
          },
        }}
      >
        <HeroCard
          title={"Intelligent Assistance"}
          content={
            "Real-time AI support for data and document access. Ask it anything, from the status of a delivery to the location of a document."
          }
          icon={
            <img
              src={heroCard1}
              style={{
                width: "170px",
                height: "170px",
                position: "absolute",
                top: "10%",
                left: "5%",
              }}
            />
          }
        />
        <HeroCard
          title={"Tailored to Your Business"}
          sx={{
            background: white.main,
            backgroundImage: `url(${cardBg})`,
            backgroundSize: "100%",
            // backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: text.main,
          }}
          icon={
            <img
              src={heroCard2}
              style={{
                width: "124px",
                height: "124px",
                position: "absolute",
                top: "10%",
                left: "60%",
              }}
            />
          }
          content={
            "Configure workflows, integrate with your existing systems in a way that aligns perfectly with your supply chain processes."
          }
        />
        <HeroCard
          sx={{
            background: `linear-gradient(155deg, #FFFFFF 30%, #75B0FF 95%, #6A6BFF 100%)`,
            color: text.main,
          }}
          icon={
            <img
              src={heroCard3}
              style={{
                width: "150px",
                height: "150px",
                position: "absolute",
                top: "10%",
                left: "55%",
              }}
            />
          }
          title={"Simplified Communication"}
          content={
            "Share updates, collaborate, and keep teams, transporters, and stakeholders on the same page effortlessly."
          }
        />
      </Box>
      {/* <Box
        sx={{
          textAlign: "center",
          padding: "3rem",
          fontSize: "2.5rem",
          position: "relative",
          letterSpacing: "-1px",
          fontWeight: "500",
          paddingTop: "8rem",
        }}
        className={"quicksand heading"}
      >
        <span
          style={{
            fontSize: "2rem",
            position: "relative",
            top: "-1rem",
            marginRight: "5px",
          }}
        >
          {sparkleSvg(primary.main)}
        </span>
        Built for Supply Chain Efficiency
      </Box> */}
    </>
  );
};

export default WhyNeatProcess;
