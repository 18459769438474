import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import logo from "assets/images/ef-logo.png";
import { GradientButton } from "components/button/buttons";
import { Link } from "react-router-dom";

const NavBar = ({ links }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;
  return (
    <>
      <Box sx={{ height: "1rem" }}></Box>
      <Box
        sx={{
          width: "80%",
          margin: "auto",
          maxWidth: "998px",
          display: "flex",
          backgroundColor: "#E5EDFD",
          borderRadius: "100px",
          padding: "10px 1rem",
          justifyContent: "space-between",
          gap: "1rem",
          alignItems: "center",
          marginBottom: "1rem",
          "@media (max-width: 800px)": {
            width: "90%",
          },
        }}
      >
        <Link to="/">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={logo} height={"40"} />
            <Box
              sx={{
                background: `-webkit-linear-gradient(45deg, ${primary.main}, ${secondary.main})`,
                backgroundClip: "text",
                textFillColor: "transparent",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              NeatProcess
            </Box>
          </Box>
        </Link>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "2.5rem",
            color: text.main,
            fontWeight: "400",
            // When on mobile, the links won't be displayed
            "@media (max-width: 500px)": {
              display: "none",
            },
          }}
        >
          {links}
        </Box>
        <GradientButton
          sx={{ width: "135px" }}
          name={"Contact Us"}
          data-tally-open="mOAaPK"
        />
      </Box>
    </>
  );
};

export default NavBar;
