import { useTheme } from "@emotion/react";
import { Box, Fade, Slide } from "@mui/material";
import { sparkleSvg } from "components/svgIcons/svgIcons";
import dot from "assets/images/svg/dot-box.svg";
import bg from "assets/images/svg/irregular.svg";
import parkins from "assets/images/testimonial/parkins.png";
import logo from "assets/images/pg-logo.png";
import star from "assets/images/svg/star.svg";
import { useState } from "react";

const Testimonials = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;

  const feedbacks = [
    {
      image: parkins,
      content:
        "ClaimDesk has transformed our logistics claims at P&G South Africa—saving time and supercharging efficiency. The Neatprocess team’s exceptional support made all the difference!",
      name: "Parkins, Luthando",
      logo: logo,
      stars: 5,
      index: 0,
    },
  ];

  const [activeFeedbackIndex, setActiveFeedbackIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const handleClick = (direction) => {
    let activeIndex;
    setFade(false);
    setTimeout(() => {
      if (direction === "left") {
        activeIndex =
          activeFeedbackIndex - 1 < 0
            ? feedbacks.length - 1
            : activeFeedbackIndex - 1;
      } else {
        activeIndex =
          activeFeedbackIndex + 1 === feedbacks.length
            ? 0
            : activeFeedbackIndex + 1;
      }
      console.log(activeFeedbackIndex, activeIndex);
      setActiveFeedbackIndex(activeIndex);
      setFade(true);
    }, 300);
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          padding: "5rem",
          fontSize: "2.5rem",
          position: "relative",
          letterSpacing: "-1px",
          fontWeight: "500",
        }}
        className={"quicksand heading"}
      >
        What Our Customers Say
        <span
          style={{
            fontSize: "2rem",
            position: "relative",
            top: "1rem",
            marginLeft: "10px",
          }}
        >
          {sparkleSvg(primary.main)}
        </span>
      </Box>
      <Box
        sx={{
          position: "relative",
          margin: "auto",
          maxWidth: "1200px",
          minHeight: "400px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: "2",
            margin: "auto",
            height: "100%",
            display: "flex",
            gap: "1rem",
            "@media (max-width: 800px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              flex: "1",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              maxWidth: "500px",
            }}
          >
            {/* <Box
              onClick={() => handleClick("left")}
              sx={{
                color: text.disabled,
                "&:hover": {
                  color: text.main,
                },
              }}
            >
              <span
                class="material-symbols-outlined"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  backgroundColor: white.main,
                  borderRadius: "100%",
                  boxShadow: "0 4px 4px #00000040",
                }}
              >
                chevron_left
              </span>
            </Box> */}
            <Box>
              <Fade in={fade} direction="left">
                <img
                  src={feedbacks[activeFeedbackIndex].image}
                  alt=""
                  style={{
                    width: "250px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
              </Fade>
            </Box>
            {/* <Box
              onClick={() => handleClick("right")}
              sx={{
                color: text.disabled,
                "&:hover": {
                  color: text.main,
                },
              }}
            >
              <span
                class="material-symbols-outlined"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  backgroundColor: white.main,
                  borderRadius: "100%",
                  boxShadow: "0 4px 4px #00000040",
                }}
              >
                chevron_right
              </span>
            </Box> */}
          </Box>
          <Fade in={fade}>
            <Box
              sx={{
                flex: "1",
                margin: "auto",
                maxWidth: "500px",
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                justifyContent: "flex-end",
                padding: "3rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "2px",
                  marginBottom: "1rem",
                }}
              >
                {Array.from(
                  { length: feedbacks[activeFeedbackIndex].stars },
                  (_, index) => (
                    <img src={star} />
                  )
                )}
                {5 - feedbacks[activeFeedbackIndex].stars > 0 &&
                  Array.from(
                    { length: 5 - feedbacks[activeFeedbackIndex].stars },
                    (_, index) => (
                      <span
                        key={index}
                        className="material-symbols-outlined"
                        style={{
                          color: "#FFB566",
                          fontSize: "1.85em",
                          marginTop: "2px",
                        }}
                      >
                        star
                      </span>
                    )
                  )}
              </Box>
              <Box
                sx={{
                  fontSize: typography.size["lg"],
                }}
              >
                {feedbacks[activeFeedbackIndex].content}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "500",
                }}
              >
                <Box>{feedbacks[activeFeedbackIndex].name}</Box>
                <img
                  src={feedbacks[activeFeedbackIndex].logo}
                  style={{ height: "60px" }}
                />
              </Box>
            </Box>
          </Fade>
        </Box>
        <img
          src={bg}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            maxWidth: "100%"
          }}
        />
        <img
          src={dot}
          alt=""
          style={{
            position: "absolute",
            left: "30%",
            top: "50px",
          }}
        />
      </Box>
    </>
  );
};

export default Testimonials;
