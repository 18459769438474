import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";

const HeroCard = ({ icon, sx, title, content }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;

  return (
    <ScrollAnimation animateIn="fadeIn" delay={100} style={{ height: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "150px",
          borderRadius: "20px",
          background: `linear-gradient(45deg, ${primary.main}, ${secondary.main})`,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          color: white.main,
          padding: "2rem",
          gap: ".5rem",
          justifyContent: "flex-end",
          boxShadow: "0 4px 10px 0 #6A6BFF40",
          overflow: "hidden",
          ...sx,
        }}
      >
        {icon}

        <Box
          sx={{
            width: "70%",
            fontWeight: "bold",
            fontSize: typography.size["xl"],
            lineHeight: "1.3em",
            position: "relative",
            zIndex: 1,
          }}
        >
          {title}
        </Box>

        <Box
          sx={{
            fontSize: typography.size["sm"],
            lineHeight: "1.3em",
            position: "relative",
            zIndex: 1,
          }}
        >
          {content}
        </Box>
      </Box>
    </ScrollAnimation>
  );
};

export default HeroCard;
