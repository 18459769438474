import { useTheme } from "@emotion/react";
import { Box, CardMedia, Collapse } from "@mui/material";
import { GradientButton } from "components/button/buttons";
import { useEffect, useRef, useState } from "react";
import { sparkleSvg } from "components/svgIcons/svgIcons";
import { Link } from "react-router-dom";

const VideoSection = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error, secondary } = palette;
  const { pxToRem } = functions;

  const tabs = [
    {
      index: 0,
      title: "Unify Systems",
      url: "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/website/DIP.mp4",
      content:
        "Collects and organizes scattered supply chain data and documents from multiple sources, linking them seamlessly for a unified view.",
    },
    {
      index: 1,
      title: "Intelligent Automation",
      url: "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/website/Claimdeskmp4",
      content:
        "Streamlines supply chain operations with AI-powered automation, reducing inefficiencies and enabling faster decision-making.",
    },
    {
      index: 2,
      title: "Predictive Insights",
      
      url: "https://neatprocesspubliccontent.s3.us-east-1.amazonaws.com/website/Analytics.mp4",
      content:
        "Providing accurate forecasts and data-driven trends to enhance precision, drive growth, and optimize supply chain performance.",
    },
  ];

  const videoRef = useRef(null);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const playNextVideo = (index) => {
    setActiveTab(tabs[(index + 1) % tabs.length]);
  };

  useEffect(() => {
    if (videoRef && videoRef.current) {
      const handleEnded = () => {
        playNextVideo(activeTab.index);
      };
      videoRef.current.addEventListener("ended", handleEnded);
      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener("ended", handleEnded);
        }
      };
    }
  }, [videoRef, activeTab]);

  return (
    <>
          <Box
        sx={{
          textAlign: "center",
          padding: "3rem",
          fontSize: "2.5rem",
          position: "relative",
          letterSpacing: "-1px",
          fontWeight: "500",
          paddingTop: "8rem",
          paddingBottom: "2rem"
        }}
        className={"quicksand heading"}
      >
        <span
          style={{
            fontSize: "2rem",
            position: "relative",
            top: "-1rem",
            marginRight: "5px",
          }}
        >
          {sparkleSvg(primary.main)}
        </span>
        Built for Supply Chain Efficiency
      </Box>
      <Box
        id="products"
        sx={{
          borderRadius: "50% / 100% 100% 0 0",
          height: "5rem",
          backgroundColor: "#b9b9ff1a",
          "@media (max-width: 900px)": {
            height: "3rem",
          },
          zIndex: "1",
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: "#b9b9ff1a",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            "@media (max-width: 700px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              paddingY: "3rem",
              paddingLeft: "3rem",
              width: "30%",
              maxWidth: "400px",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "b9b9ff1a",
              "@media (max-width: 1000px)": {
                width: "50%",
                maxWidth: "none",
                padding: "1.5rem",
              },
              "@media (max-width: 700px)": {
                width: "100%",
                maxWidth: "none",
                padding: "1.5rem",
              },
            }}
          >
            <Box
              sx={{
                "@media (max-width: 700px)": {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly"
                },
              }}
            >
              {tabs.map((tab) => (
                <Box
                  sx={{
                    padding: "1rem",
                    backgroundColor: white.main,
                    borderRadius: "15px",
                    marginBottom: "2rem",
                    position: "relative", // Ensure the pseudo-element aligns correctly
                    "@media (max-width: 700px)": {
                      marginBottom: "1rem",
                      width:"35%",
                      textAlign: "center",
                      transform: "scale(0.85)",
                      marginBottom: "0rem",
                    },
                    cursor: "pointer",
                    boxShadow: activeTab.index != tab.index
                    ? null
                    : `2px 2px 5px grey`,
                  transform: activeTab.index == tab.index ? "translateY(-5px)" : "translateY(0)",
                  transition: "transform 0.2s ease, box-shadow 0.2s ease",
                  }}
                  onClick={() => setActiveTab(tab)}
                >
                  <Box
                    sx={{
                      background: `-webkit-linear-gradient(45deg, ${primary.main}, ${secondary.main})`,
                      backgroundClip: "text",
                      textFillColor: "transparent",
                      fontWeight: "500",
                      fontSize: typography.size["xl"],
                      "@media (max-width: 1200px)": {
                        fontSize: typography.size["lg"],
                      },
                      "@media (max-width: 700px)": {
                        fontSize: typography.size["md"],
                      },
                      "@media (max-width: 500px)": {
                        fontSize: typography.size["sm"],
                      },
                    }}
                  >
                    {tab.title}
                  </Box>
                  <Collapse in={tab.title === activeTab.title}>
                    <Box
                      sx={{
                        paddingY: ".7rem",
                        fontSize: typography.size["sm"],

                      "@media (max-width: 1000px)": {
                        display: "none",
                      },
                      "@media (max-width: 700px)": {
                        fontSize: typography.size["xs"],
                        
                      },
                      "@media (max-width: 500px)": {
                        fontSize: typography.size["xs"],
                      },
                        lineHeight: "1.3em",
                      }}
                    >
                      {tab.content}
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </Box>
            <GradientButton
              name={"Learn More"}
              // component={Link}
              to={"/products"}
              data-tally-open="mOAaPK"
              sx={{
                padding: ".5rem 2rem",
                // on mobile, the button won't be displayed
                "@media (max-width: 700px)": {
                  display: "none",
                },
              }}
              endIcon={
                <span
                  class="material-symbols-outlined"
                  style={{ color: white.main }}
                >
                  east
                </span>
              }
            />
          </Box>

          <Box
            sx={{
              paddingX: "3rem",
              backgroundColor: white.main,
              width: "60%",
              borderRadius: "20px",
              height: "420px",
              width: "810px",
              "@media (max-width: 1000px)": {
                width: "100%",
                maxWidth: "none",
                height: 'auto'
              },
            }}
          >
            <CardMedia
              component="video"
              muted="true"
              autoPlay="true"
              // loop="true"
              src={activeTab.url}
              ref={videoRef}
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "20px",
                objectFit: "contain",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            borderRadius: "50% / 100% 100% 0 0",
            height: "5rem",
            backgroundColor: white.main,
            marginTop: "2rem",
            "@media (max-width: 900px)": {
              height: "3rem",
            },
          }}
        ></Box>
      </Box>
    </>
  );
};

export default VideoSection;
